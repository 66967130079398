import { memo } from "react";
import { useNavigate } from "react-router-dom";

import AppArticleCardDto from "dto/components/app-article-card-dto";

import formatDate from "common/format-date";

const AppArticleCard = ({ title, description, image, link, date, location }: AppArticleCardDto) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(link);
	};

	const formattedPublicationDate = date ? formatDate(date) : null;

	return (
		<div className="app-article-card-wrapper">
			<div className="app-article-card">
				<div className="article-card">
					<img className="article-card__image" src={image} alt="articleImage" onClick={handleClick} />

					<p className="article-card__title">{title}</p>

					<p className="article-card__description">
						{description} {date && <span>|</span>} {formattedPublicationDate} {location && <span>|</span>} {location}
					</p>
				</div>
			</div>
		</div>
	);
};

export default memo(AppArticleCard);
