//library
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// hooks
import useWindowSize from "hooks/use-window-size";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ArticleDetailsDto, ArticleDto } from "dto/components/app-article-info/app-article-dto";

// components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import AppArticleCard from "components/app-article-card";
import AppArticleInfo from "components/app-article-info";
import AppArticleDetails from "components/app-article-details";

const PageArticle = () => {
	const navigate = useNavigate();
	const sizes = useWindowSize();
	const { id } = useParams();
	const [articleDetails, setArticleDetails] = useState<ArticleDetailsDto>();
	const [similarArticles, setSimilarArticles] = useState<ArticleDto[]>([]);
	const [headerClassName, setHeaderClassName] = useState("app-navbar__fixed");
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	const isMobileView = useMemo(() => (sizes?.[0] <= 489 ? true : false), [sizes]);

	const onHandleGetSimilarArticles = useCallback(async (id: string, category: string) => {
		try {
			const params = queryParams.current;

			const payload = { size: 10, page: params.page };

			const response = await api.get.similarCategory.article(id, category, payload);

			setSimilarArticles(response.data.data.list.content.slice(0, 3));
		} catch (error) {
			serveRequestErrors(error as Error | ErrorResponseDto);
		}
	}, []);

	const onHandleGetArticleDetails = useCallback(async () => {
		if (!id) return;

		try {
			const response = await api.get.articleDetails.article(id);
			const data = response.data.data;

			setArticleDetails(data);
			onHandleGetSimilarArticles(id, data.category);
		} catch (error) {
			serveRequestErrors(error as Error | ErrorResponseDto);
		}
	}, [id, onHandleGetSimilarArticles]);

	useEffect(() => {
		const isFixedHeader = () => {
			const scrolledTo = window.scrollY;

			if (scrolledTo >= 120) setHeaderClassName("app-navbar__fixed--active");
			else setHeaderClassName("app-navbar__fixed");
		};

		window.addEventListener("scroll", isFixedHeader);

		return () => window.removeEventListener("scroll", isFixedHeader);
	}, []);

	useEffect(() => {
		onHandleGetArticleDetails();
	}, [onHandleGetArticleDetails]);

	return (
		<div className="page-article">
			<div className="article">
				<AppNavbar className={headerClassName} />

				{/*prettier-ignore*/}
				<header className="article__header">
					<div className="article__header-img" style={{ backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0), rgba(20, 20, 20, 0.88) 99.99%, rgba(20, 20, 20, 1)), url(${articleDetails?.thumbnail})` }}></div>
                    <div className="article__header-text">
						<p>{articleDetails?.title}</p>
					</div>
				</header>

				<div className="content">
					<div className="content__left">
						<AppArticleInfo publicationDate={articleDetails?.publicationDate} category={articleDetails?.category} tableOfContent={articleDetails?.tableOfContent || []} location={articleDetails?.location} />
					</div>

					<div className="content__right">
						<AppArticleDetails content={articleDetails?.content || []} />
					</div>
				</div>

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">Similar Articles</p>

						{!isMobileView && <AppButton label="View All Articles" onClick={() => navigate(pathnames.PageAllArticles)} tertiary />}
					</div>

					<div className="articles-section__wrapper">
						{similarArticles.map((o, i) => (
							<AppArticleCard title={o.title} description={o.category} image={o.thumbnail} key={o.id} location={o.location} date={o.date} link={`${pathnames.PageArticle}/${o.id}`} />
						))}
					</div>

					{isMobileView && (
						<div className="articles-section__button-wrapper">
							<AppButton label="View All Articles" onClick={() => navigate(pathnames.PageAllArticles)} tertiary />
						</div>
					)}
				</div>

				<AppNewsCard />
				<AppFooter />
			</div>
		</div>
	);
};

export default PageArticle;
