import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, FormControl, TextField } from "@mui/material";

import classNames from "common/class-names";

import AppFileUploadFieldDto from "dto/components/app-file-upload-field-dto";

import wasteBinIcon from "assets/images/waste-bin.svg";

const AppFileUploadField = (p: AppFileUploadFieldDto) => {
	const { label, name, required, acceptedFormats = "jpeg, docx, pdf, pptx", maxSize = 4, disabled, onChange, error } = p;

	const [fileName, setFileName] = useState<string>("No file chosen");
	const [exceedFileSize, setExceedFileSize] = useState<string>("");

	const inputClassNames = useMemo(() => classNames({ "file-upload__body": true, "file-upload__body--error": Boolean(error) }), [error]);

	const onHandleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null;

		if (file) {
			const fileSizeInMB = file.size / 1024 / 1024;

			if (fileSizeInMB > maxSize) {
				setExceedFileSize(`Uploaded file cannot exceed ${maxSize}MB`);
				setFileName("No file chosen");
				onChange(null);
			} else {
				setFileName(file.name);
				setExceedFileSize("");
				onChange(file);
			}
		} else {
			setFileName("No file chosen");
			setExceedFileSize("");
			onChange(null);
		}
	};

	const onHandleDeleteFile = useCallback(() => {
		setFileName("No file chosen");
		setExceedFileSize("");
		onChange(null);
	}, [onChange]);

	const Footer = useCallback(() => {
		return (
			<div className="file-upload__footer">
				<p className="file-upload__info">
					Accepted formats: {acceptedFormats} (not more than {maxSize}MB)
				</p>

				{exceedFileSize && <p className="file-upload__error">{exceedFileSize}</p>}

				{error && <p className="file-upload__error">{error}</p>}
			</div>
		);
	}, [acceptedFormats, maxSize, exceedFileSize, error]);

	useEffect(() => {
		if (p.value === null && fileName !== "No file chosen") onHandleDeleteFile();
	}, [fileName, onHandleDeleteFile, p.value]);

	return (
		<div className="app-file-upload-field">
			<FormControl className="file-upload__form-control">
				<label className="file-upload__label" htmlFor={name}>
					{label}
					{required && <span className="file-upload__required"> *</span>}
				</label>

				<div className={inputClassNames}>
					<TextField
						disabled
						name={name}
						placeholder={fileName}
						value={fileName}
						className={`file-upload__textfield ${fileName !== "No file chosen" ? "file-upload__file-uploaded" : ""}`}
						InputProps={{
							endAdornment: fileName !== "No file chosen" && (
								<div onClick={onHandleDeleteFile} className="file-upload__icon">
									<img src={wasteBinIcon} alt="" />
								</div>
							),
						}}
					/>

					<label htmlFor={name} className="file-upload__button-label">
						<input className="file-upload__input" id={name} name={name} type="file" onChange={onHandleFileChange} disabled={disabled} />

						<Button variant="contained" component="span" className="file-upload__button">
							Upload file
						</Button>
					</label>
				</div>

				<Footer />
			</FormControl>
		</div>
	);
};

export default memo(AppFileUploadField);
