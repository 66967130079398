import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { CategoryDropdownProps, ImperativeHandleCategoryDropdownDto } from "dto/components/app-category-dropdown";

const AppCategoryDropdown = ({ categories, onSelect }: CategoryDropdownProps, ref: React.ForwardedRef<ImperativeHandleCategoryDropdownDto>) => {
	const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

	const onHandleChange = (e: any) => {
		const selectedCategory = categories.find((category) => category.id === e.target.value);

		if (selectedCategory) {
			setSelectedCategoryId(selectedCategory.id);
			onSelect(selectedCategory);
		}
	};

	useImperativeHandle(ref, () => ({
		selectedDetails: categories?.filter((category) => category.id === selectedCategoryId) || [],
	}));

	useEffect(() => {
		if (categories.length > 0) {
			setSelectedCategoryId(categories[0].id);
			onSelect(categories[0]);
		}
	}, [categories, onSelect]);

	return (
		<FormControl fullWidth>
			<Select
				variant="outlined"
				value={selectedCategoryId}
				onChange={onHandleChange}
				IconComponent={KeyboardArrowDownIcon}
				sx={{
					backgroundColor: "#1a1a1a",
					fontSize: "14px",
					borderRadius: "6px",
					color: "#ffffff",
					"& .MuiSelect-icon": {
						color: "#ffffff",
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#262626 !important",
					},
				}}
				MenuProps={{
					PaperProps: {
						sx: {
							backgroundColor: "#1a1a1a",
							color: "#ffffff",
							fontSize: "14px",
							maxHeight: "240px",
							overflow: "auto",
						},
					},
				}}
			>
				{categories.map((category) => (
					<MenuItem key={category.id} value={category.id} sx={{ backgroundColor: "#1a1a1a", color: "#ffffff", fontSize: "14px" }}>
						{category.title.toUpperCase()}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default memo(forwardRef(AppCategoryDropdown));
