import { createSlice } from "@reduxjs/toolkit";

import AlertSliceDto from "dto/redux/alert-slice-dto";

const initialState: AlertSliceDto[] = [];

export const alertSlice = createSlice({
	name: "alert",
	initialState,
	reducers: {
		promptAlertMessage: (state, action) => {
			return [...state, action.payload];
		},
		dismissAlertMessage: (state) => {
			const nextState = [...state];

			nextState.shift();

			return nextState;
		},
		dismissAllAlertMessage: () => initialState,
	},
});

export const { promptAlertMessage, dismissAlertMessage, dismissAllAlertMessage } = alertSlice.actions;

export default alertSlice.reducer;
