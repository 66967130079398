import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "common/class-names";

import AppInputDto from "dto/components/app-input-dto";

import lockIcon from "assets/images/lock-icon.svg";
import eyeOpenIcon from "assets/images/components/app-input/eye-open-icon.svg";
import eyeCloseIcon from "assets/images/components/app-input/eye-close-icon.svg";

const AppInput = (p: AppInputDto) => {
	const { disabled, touched, error, onFormat, onCurrencyFormat, ...props } = p;

	const ref = useRef<HTMLInputElement>(null);

	const [passwordVisible, setPasswordVisible] = useState(false);

	const isPasswordType = useMemo(() => props.type === "password", [props.type]);

	const maxLength = useMemo(() => props.maxLength ?? 50, [props.maxLength]);

	const isNumberType = useMemo(() => props.type === "number", [props.type]);

	const isInvalid = useMemo(() => Boolean(error) && Boolean(touched), [error, touched]);

	const placeholder = useMemo(() => props.placeholder ?? "Enter Your " + (props.label ?? ""), [props.placeholder, props.label]);

	const type = useMemo(() => {
		if ((isPasswordType && passwordVisible) || isNumberType) {
			return "text";
		}

		return props.type;
	}, [isPasswordType, isNumberType, passwordVisible, props.type]);

	const inputClassNames = useMemo(() => classNames({ input: true, "input--error": isInvalid, "input--disabled": Boolean(disabled) }), [disabled, isInvalid]);

	const onTogglePasswordVisibility = useCallback(() => {
		setPasswordVisible((prev) => !prev);
	}, []);

	//prettier-ignore
	const onHandleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if(isNumberType) {
			switch (event.key) {
				case "e":
				case "-":
				case "+":
				case ".":
					event.preventDefault();
					break;
				default:
					break;
			}
		}

		if(props.onKeyDown) props.onKeyDown(event);
	}, [isNumberType, props]);

	//prettier-ignore
	const onHandleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
		event.target.value = event.currentTarget.value.trim();
		
		if (props.onBlur) {
			props.onBlur(event);
		} else if(props.onChange) {
			props.onChange(event);
		}
	}, [props]);

	//prettier-ignore
	const onHandleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if(!props.onChange) return;

        if (isNumberType) {
            event.target.value = event.target.value.replace(/[^\d]/g, "");
            props.onChange(event);
        } else {
			if(onCurrencyFormat) {
				event = onCurrencyFormat(event);
			} else if(onFormat) {
				event = onFormat(event);
			}
			
            props.onChange(event);
        }
    }, [isNumberType, onCurrencyFormat, onFormat, props]);

	const PasswordIcon = useCallback((obj: { disabled: boolean; isPassword: boolean; visible: boolean; onToggle: VoidFunction }) => {
		if (!obj.isPassword) return null;

		let icon = null;

		if (obj.disabled) {
			icon = lockIcon;
		} else if (obj.visible) {
			icon = eyeCloseIcon;
		} else {
			icon = eyeOpenIcon;
		}

		return (
			<button type="button" className="input__icon-button" onClick={obj.onToggle}>
				<img src={icon} alt={obj.visible ? "hide password" : "show password"} />
			</button>
		);
	}, []);

	const Icon = useCallback((obj: { disabled: boolean; isPassword: boolean }) => {
		if (!obj.disabled || obj.isPassword) return null;

		return (
			<div className="input__icon">
				<img src={lockIcon} alt="input disabled" />
			</div>
		);
	}, []);

	useEffect(() => {
		let currentRef: HTMLInputElement | null = null;

		const onHandleSetCursorToEnd = (event: MouseEvent) => {
			const target = event.target as HTMLInputElement;

			if (target) {
				if (!target.value) target.value = "00.00";

				target.setSelectionRange(target.value.length, target.value.length);
			}
		};

		if (onCurrencyFormat && ref.current) {
			ref.current.addEventListener("click", onHandleSetCursorToEnd);
			currentRef = ref.current;
		}

		return () => {
			currentRef?.removeEventListener("click", onHandleSetCursorToEnd);
		};
	}, [onCurrencyFormat]);

	return (
		<div className="app-input">
			<div className={inputClassNames}>
				<label className="input__label" htmlFor={props.name}>
					{props.label}

					{props.required && <span className="input__required"> *</span>}
				</label>

				<div className="input__body">
					<input
						id={props.name}
						className="input__input"
						ref={ref}
						{...props}
						maxLength={maxLength}
						autoComplete="off"
						required={false}
						type={type}
						placeholder={placeholder}
						onKeyDown={onHandleKeyDown}
						onBlur={onHandleBlur}
						onChange={onHandleChange}
					/>

					<PasswordIcon disabled={Boolean(disabled)} isPassword={isPasswordType} visible={passwordVisible} onToggle={onTogglePasswordVisibility} />

					<Icon disabled={Boolean(disabled)} isPassword={isPasswordType} />
				</div>

				{isInvalid && (
					<div className="input__footer">
						<p className="input__error">{error}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(AppInput);
