import { AxiosResponse } from "axios";
import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";
import { SelectOption } from "dto/components/app-select-input-dto";
import ErrorResponseDto from "dto/services/error-response-dto";

const getAllVacanciesListing = async (): Promise<AxiosResponse<{ content: SelectOption[] }>> => {
    let response: AxiosResponse<{ content: SelectOption[] }> | null = null;
    let errorMessage = "";

    try {
        const res = await api.get.career.hiring();

        const content: SelectOption[] = Array.from(new Set(res.data.data.list.map((o: SelectOption) => o.employmentType)))
            .map((employmentType) => ({
                label: employmentType as string, 
                value: employmentType as string,
            }));

        content.unshift({ label: "All Vacancies", value: "" });

        response = { ...res, data: { content } };
    } catch (error) {
        const err = error as Error | ErrorResponseDto;
        serveRequestErrors(err);
    }

    if (response) return response;

    throw new Error(errorMessage);
};

export default getAllVacanciesListing;