import { memo, useCallback, useMemo } from "react";

import classNames from "common/class-names";

import AppTextareaDto from "dto/components/app-textarea-dto";

import lockIcon from "assets/images/lock-icon.svg";

const AppTextarea = (p: AppTextareaDto) => {
	const { touched, error, customHeight, ...props } = p;

	const isInvalid = useMemo(() => Boolean(error) && Boolean(touched), [error, touched]);

	const maxLength = useMemo(() => props.maxLength ?? 255, [props.maxLength]);

	const placeholder = useMemo(() => props.placeholder ?? "Enter Your " + props.label, [props.placeholder, props.label]);

	const textareaClassNames: string = classNames({ input: true, "textarea--error": Boolean(error) && Boolean(touched), "textarea--disabled": Boolean(props.disabled), "textarea--custom-height": Boolean(customHeight) });

	const Icon = useCallback((obj: { disabled: boolean }) => {
		if (!obj.disabled) return null;

		return (
			<div className="input__icon">
				<img src={lockIcon} alt="input disabled" />
			</div>
		);
	}, []);

	const Footer = useCallback((obj: { isInvalid: boolean; error?: string; value: string; maxLength: number }) => {
		return (
			<div className="textarea__footer">
				<p className="textarea__error">
					{obj.isInvalid ? obj.error : ""} <span className="textarea__count">{`${obj.value.length}/${obj.maxLength}`}</span>
				</p>
			</div>
		);
	}, []);

	return (
		<div className="app-textarea">
			<div className={textareaClassNames}>
				<label className="textarea__label" htmlFor={props.name}>
					{props.label}

					{props.required && <span className="textarea__required"> *</span>}
				</label>

				<div className="textarea__body">
					<textarea id={props.name} className="textarea__input" {...props} required={false} placeholder={placeholder} autoComplete="off" maxLength={maxLength} />

					<Icon disabled={Boolean(props.disabled)} />
				</div>

				<Footer isInvalid={isInvalid} error={error} maxLength={maxLength} value={props.value ?? ""} />
			</div>
		</div>
	);
};

export default memo(AppTextarea);
