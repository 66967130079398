// src/store/store.ts
import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "store/slices/alert-slice"; // Import your slice

export const store = configureStore({
	reducer: {
		alert: alertSlice, // Add your slice reducers here
	},
});

// Define types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
