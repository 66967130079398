import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";

const Page404 = () => {
	const navigate = useNavigate();

	const onHandleClick = () => {
		navigate(pathnames.PageHome);
	};

	return (
		<div className="page-404">
			<div className="page-404__animate"></div>

			<h1 className="page-404__title">The page you were looking for does not exist</h1>

			{/*prettier-ignore*/}
			<button className="page-404__cta" onClick={onHandleClick}>Back to Home</button>
		</div>
	);
};

export default Page404;
