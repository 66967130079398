import { useEffect, useMemo, useState } from "react";
import useWindowSize from "hooks/use-window-size";

import { TimelineProps } from "dto/components/app-timeline-dto";

const AppTimeline = ({ year, title, description, index }: TimelineProps) => {
	const sizes = useWindowSize();
	const [isExpanded, setIsExpanded] = useState(false);
	const formattedDesc = description.replace(/-/g, "‑");
	const isMobileView = useMemo(() => (sizes?.[0] <= 767 ? true : false), [sizes]);

	const toggleExpand = () => {
		if (isMobileView) setIsExpanded((prev) => !prev);
	};

	useEffect(() => {
		if (isMobileView && index === 0) setIsExpanded(true);
	}, [isMobileView, index]);

	return (
		<div className="app-timeline">
			<div className="app-timeline__wrapper" onClick={toggleExpand}>
				<p className="app-timeline__year--text">Year</p>
				<p className="app-timeline__year">{year}</p>
			</div>

			{(isExpanded || !isMobileView) && (
				<>
					<div className="app-timeline__content">
						<p className="app-timeline__title">{title}</p>
					</div>

					<div>
						<p className="app-timeline__description">{formattedDesc}</p>
					</div>
				</>
			)}
		</div>
	);
};

export default AppTimeline;
