import { useCallback, useEffect, useMemo, useState } from "react";

import api from "services/api";

import useTemplateContext from "hooks/use-template-context";

import { JourneyListDto } from "dto/pages/page-our-story-dto";
import ErrorResponseDto from "dto/services/error-response-dto";

import serveRequestErrors from "common/serve-request-errors";

import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppTimeline from "components/app-timeline";
import HeaderCard from "components/app-header-card";

const PageOurStory = () => {
	const { getFilteredResources } = useTemplateContext();
	const [journeyList, setJourneyList] = useState<JourneyListDto[]>([]);
	const { storyCover, storyHeader, backgroundImage } = useMemo(() => {
		const storyPage = getFilteredResources("page.journey") || [];
		return {
			storyCover: storyPage.find((item) => item.key === "page.journey.cover.image")?.value?.toString() || "",
			storyHeader: storyPage.find((item) => item.key === "page.journey.cover.title")?.value?.toString() || "",
			backgroundImage: storyPage.find((item) => item.key === "page.journey.background.image")?.value?.toString() || "",
		};
	}, [getFilteredResources]);

	const onHandleGetJourneyList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.story.story();

			setJourneyList(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const formatYear = (startDate: string, endDate: string) => {
		const startYear = new Date(startDate).getFullYear();
		const endYear = new Date(endDate).getFullYear();

		if (startYear === endYear) {
			return `${startYear}`;
		}

		return `${startYear} to ${endYear}`;
	};

	useEffect(() => {
		onHandleGetJourneyList();
	}, [onHandleGetJourneyList]);

	return (
		<div className="page-our-story">
			{journeyList?.length > 0 && (
				<div
					className="static-background"
					style={{
						backgroundImage: `url(${backgroundImage})`,
					}}
				></div>
			)}

			<div className="story">
				<AppNavbar />

				<HeaderCard backgroundImage={storyCover} title={storyHeader} isLargeTitle removeBackgroundColor headerType="top" />

				<div className="timeline">
					{journeyList.map((item, index) => (
						<AppTimeline key={index} index={index} year={formatYear(item.startDate, item.endDate)} title={item.title} description={item.description} />
					))}
				</div>
			</div>

			<AppFooter />
		</div>
	);
};

export default PageOurStory;
