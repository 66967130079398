// library
import { useRef, useMemo } from "react";

// hooks
import useTemplateContext from "hooks/use-template-context";

// dto
import { ImperativeHandleAppSubscribeCardDto } from "dto/components/app-subscribe-card-dto";

// components
import AppButton from "components/app-button";
import AppSubscribeCard from "components/app-subscribe-card";

const AppNewsCard = () => {
	const subscribeRef = useRef<ImperativeHandleAppSubscribeCardDto>(null);
	const { getFilteredResources } = useTemplateContext();
	const newsletterPage = useMemo(() => getFilteredResources("page.landing"), [getFilteredResources]);
	const newsCardCover = useMemo(() => (newsletterPage?.find((value) => value.key === "page.landing.newsletter.cover")?.value ?? "").toString(), [newsletterPage]);
	const newsCardHeader = useMemo(() => (newsletterPage?.find((value) => value.key === "page.landing.newsletter.header")?.value ?? "").toString(), [newsletterPage]);
	const newsCardDesc = useMemo(() => (newsletterPage?.find((value) => value.key === "page.landing.newsletter.description")?.value ?? "").toString(), [newsletterPage]);
	const newsCardLabel = useMemo(() => (newsletterPage?.find((value) => value.key === "page.landing.newsletter.cta.label")?.value ?? "").toString(), [newsletterPage]);

	const onHandleShow = () => {
		subscribeRef.current?.onHandleShow();
	};

	return (
		<div className="app-news-card">
			<div className="news-card">
				<div className="news-card__image" style={{ backgroundImage: `url(${newsCardCover})` }}>
					<div className="news-card__container">
						<div className="news-card__wrapper">
							<p className="news-card__title">{newsCardHeader}</p>

							<p className="news-card__description">{newsCardDesc}</p>
						</div>

						{newsCardLabel && <AppButton label={newsCardLabel} onClick={onHandleShow} className="news-card__btn"/>}
					</div>
				</div>

				<AppSubscribeCard ref={subscribeRef} />
			</div>
		</div>
	);
};
export default AppNewsCard;
