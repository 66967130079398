import Marquee from "react-fast-marquee";

import { AppSlideShowProps } from "dto/components/app-slideshow-dto";

const AppSlideShow = ({ children, sliderBackgroundColor }: AppSlideShowProps) => {
	return (
		<Marquee style={{ backgroundColor: sliderBackgroundColor ?? "transparent" }} className="app-slideshow" autoFill={true} speed={30}>
			{children}
		</Marquee>
	);
};

export default AppSlideShow;
